import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import images

import logo from "../../images/logo.svg";

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <React.Fragment>
     <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <Link className="logo-footer" to="/"><img src={logo} style={{width:"80%"}} alt="Andromedia Tunisie"/></Link>
                        <p className="mt-4">Andromedia vous accompagne, grâce à l'expertise de son équipe en nouvelles technologies, dans le développement de la notoriété de votre marque, l'optimisation de ses performances et la bonne gestion de vos ressources internes.
                        </p>
                        <ul className="list-unstyled social-icon social mb-0 mt-4">
                            <li className="list-inline-item"><a rel="noreferrer" href="https://www.facebook.com/andromediaTunisie" target="_blank" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></a></li>
                            <li className="list-inline-item"><a  rel="noreferrer" href="https://www.instagram.com/andromedia.io/" target="_blank"className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></a></li>
                            <li className="list-inline-item"><a rel="noreferrer" href="https://www.linkedin.com/company/andromediatunisie" className="rounded" target="_blank"><i className="mdi mdi-linkedin" title="Linkedin"></i></a></li>
                            <li className="list-inline-item"><a rel="noreferrer" href="https://www.youtube.com/channel/UCNUCi-cA0pEbdlyq1KUtcyg" className="rounded" target="_blank"><i className="mdi mdi-youtube" title="Youtube"></i></a></li>

                        </ul>
                    </div>
                    
                    <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">

                    </div>
                    
                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Naviguer</h4>
                        <ul className="list-unstyled footer-list mt-4">
                            <li><Link to="/qui-sommes-nous" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> A propos de nous.</Link></li>
                            <li><Link to="/nos-services" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Nos services.</Link></li>
                            <li><Link to="/contact" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Contact.</Link></li>

                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Contact</h4>
                        <ul className="list-unstyled footer-list mt-4">
                            <li>Téléphone: +216 21 78 48 26</li>
                            <li><Link to="#" className="text-foot"></Link></li>
                            <li><Link to="#" className="text-foot"></Link></li>
                            <li><Link to="#" className="text-foot"></Link></li>
                            <li>Mail:<a to="mailto:contact@andromedia.io" className="text-foot"> contact@andromedia.io</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <hr />
        <footer className="footer footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <div className="text-sm-left">
                            <p className="mb-0">©  {new Date().getFullYear()}  Développé par Andromedia</p>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
        
      </React.Fragment>
    );
  }
}

export default Footer;
