import React, { Component } from 'react';
import Layout from './components/Layout/';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';
import logo from "./images/logo.svg";
import {Helmet} from 'react-helmet'
// Import Css
import './Apps.scss';
import './css/materialdesignicons.min.css';

// Include Routes 
import routes from './routes';
import { loadReCaptcha } from 'react-recaptcha-google';
// Root Include
const Root = React.lazy(() => import('./pages/Home/indexRoot'));



function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    componentDidMount() {
      loadReCaptcha();
    }
    render() {
      return <Layout>
        <WrappedComponent></WrappedComponent>
      </Layout>
    }
  };
}

class App extends Component {

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <title>Andromedia Agency</title>
          <meta name="keywords" content="digital,agency,IT,IOT,systèmes embarqués,Unity3D,game dev, web agency,mobile dev, programming,tunisie,tunisia,programmation,informatique, agence digitale, web design, graphique,graphic design,sponsoring,facebook,instagram, securité, réalité augmentée, réalité virtuelle,VR,AR,JS,ads,HTML,CSS,XML,JavaScript"/>
          <meta name="description" content="Andromedia est une agence spécialisée dans l’IT et le marketing digital."/>
        </Helmet>
      <Router>
          <React.Suspense fallback={<div></div>}>
            <Switch>
            {routes.map((route, idx) =>
                <Route path={route.path} component={withLayout(route.component)} key={idx} />
            )}

            <Route path="/" component={Root} />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);