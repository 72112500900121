import React from 'react';

// Import all components

const Main = React.lazy(() => import('./pages/Agency/index'));
const PageAboutUs = React.lazy(() => import('./pages/PageAboutUs'));
const PageContactOne = React.lazy(() => import('./pages/PageContactOne'));
const PageServices = React.lazy(() => import('./pages/PageServices'));


const routes = [

    // public Routes

    { path: '/qui-sommes-nous', component: PageAboutUs },

    { path: '/contact', component: PageContactOne },

    { path: '/nos-services', component: PageServices },

    { path: '/', component: Main }



];

export default routes;